import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { getYaNative } from '@yanolja-rnd/ya-native'
import { EmptyState } from '@yanolja-rnd/ya-com-empty-state'
import Color from '~/app/styled/color'
import conf from '~/app/conf'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.white};
`

export default function NotFound() {
  const onClick = useCallback(() => {
    const yaNative = getYaNative()
    if (yaNative.isEnabled) {
      yaNative.deepLink({ link: conf.app.home.url })
    } else {
      window.location.replace(conf.web.url)
    }
  }, [])

  return (
    <Container>
      <EmptyState.IconType
        title={'요청하신 페이지가 존재하지 않습니다'}
        description={'404 Not Found'}
        iconType={'error'}
        buttons={{
          button1: { content: '홈으로 바로가기', onClick },
        }}
      />
    </Container>
  )
}
