export default {
  environment: 'prod',
  web: {
    url: 'https://www.yanolja.com',
  },
  app: {
    home: {
      url: 'yanoljamotel://Home',
    },
  },
  policy: {
    url: 'https://policy.yanolja.com',
  },
  'order-gw': {
    url: 'https://order-gw.yanolja.com',
  },
  'platform-site': {
    url: 'https://platform-site.yanolja.com',
  },
  'nol-point': {
    startDate: '2024-09-01',
  },
}
